import {Aside} from "../component/Aside";
import {ProfileCenter} from "./profile/ProfileCenter";

const Profile = () => {

  return (
      <div className="main-container">
          <Aside />
          <ProfileCenter />
      </div>
  );

}

export {Profile};
