const ProfileHeader = () => {
  return (
      <div className="profile-header">
          <span className="profile-header-item">Назад</span>
          <span className="profile-header-item">Учетная запись</span>
      </div>
  );
}

export {ProfileHeader};
