const ProbeListHeader = () => {
    return (
        <li className='probe-list-item probe-list-item-header'>
            <input type="checkbox" className="probe-list-item-1"/>
            <span className="probe-list-item-2">Проба</span>
            <span className="probe-list-item-3">S</span>
            <span className="probe-list-item-4">Культура</span>
            <span className="probe-list-item-5">Дата</span>
            <span className="probe-list-item-6">Протеин</span>
            <span className="probe-list-item-7">Влажность</span>
            <span className="probe-list-item-8">Углеводы</span>
            <span className="probe-list-item-9">Масличность</span>
            <span className="probe-list-item-10">К</span>
        </li>
    );
}

export {ProbeListHeader};
