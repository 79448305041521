const ProbeHeader = () => {
    return (
        <div className="header header-right">
            <span className="header-left" id='header-title'>Место взятия пробы</span>
            <div className="header-right">
                <button className="btn-reset header-button header-button-right">Добавить</button>
            </div>
        </div>
    );
}

export {ProbeHeader};
