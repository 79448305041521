import React from "react";

const AuthMenu = class AuthMenu extends React.Component {
    render() {
        return <div className="auth-menu">
            <button className="btn-reset auth-menu-button auth-menu-button-active">Вход</button>
            <button className="btn-reset auth-menu-button">Регистрация</button>
        </div>;
    }
}

export default AuthMenu;
