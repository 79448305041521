const Header = () => {
    return (
        <div className="header">
            <span className="header-left" id='header-title'>Пробы</span>
            <div className="header-right">
                <span className="header-right-text">Поле в Москва</span>
                <input className="header-right-date" type="date"/>
                <button className="btn-reset header-button">Excel</button>
                <button className="btn-reset header-button">Фильтр</button>
            </div>
        </div>
    );
}

export {Header};
