import {Header} from "../../component/headers/Header";
import {ProbeListHeader} from "../../component/probe/ProbeListHeader";
import {ProbeItem} from "../../component/probe/ProbeItem";

const ProbeCenter = () => {

    const probes = [
        {
            name: 'Проба 1',
            s: '6.1',
            culture: 'Овёс',
            date: '15.06.2024 11:15',
            protein: '13.78',
            humidity: '11.23',
            carbohydrates: '84.23',
            oil_content: '2.54'
        },
        // {
        //     name: 'Проба 2',
        //     s: '8.2',
        //     culture: 'Зерно',
        //     date: '25.06.2024 12:45',
        //     protein: '14.85',
        //     humidity: '12.65',
        //     carbohydrates: '72.65',
        //     oil_content: '3.54'
        // },
        // {
        //     name: 'Проба 3',
        //     s: '15.6',
        //     culture: 'Рожь',
        //     date: '17.05.2024 13:12',
        //     protein: '45.25',
        //     humidity: '37.24',
        //     carbohydrates: '12.98',
        //     oil_content: '5.98'
        // },
    ];

    return (
        <div>
            <Header />
            <div className="probe-center-wrapper">
                <div className="probe-select">
                    <button className="btn-reset probe-select-button probe-select-button-active">Пробы</button>
                    <button className="btn-reset probe-select-button">Новые Пробы</button>
                </div>
                <ul className="list-reset">
                    <ProbeListHeader />
                    {probes.map(probe => {
                        return <ProbeItem probe={{probe}}/>
                    })}
                </ul>
            </div>
        </div>
    );

}

export {ProbeCenter};
