import {ProbeHeader} from "../../component/headers/ProbeHeader";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

const ProbeRight = () => {

    return (
        <div>
            <ProbeHeader />
            <YMaps>
                <Map defaultState={{ center: [55.968459, 35.512577], zoom: 15, type: 'yandex#satellite' }} width={'100%'} height={'calc(100% - 60px)'}>
                    <Placemark defaultGeometry={[55.968755, 35.513838]} />
                </Map>
            </YMaps>
        </div>
    );

}

export {ProbeRight};
