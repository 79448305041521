import {NavLink} from "react-router-dom";

const activeLink = ({isActive}) => isActive ? 'aside-link aside-link-active' : 'aside-link';

const Aside = () => {
    return (
        <aside className="aside">
            <ul className="list-reset aside-list">
                <li className="aside-list-item">
                    <NavLink to='/' className={activeLink}>Пробы</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/1' className={activeLink}>Поля</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/3' className={activeLink}>Транспорт</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/4' className={activeLink}>Хранение</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/5' className={activeLink}>Погода</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/6' className={activeLink}>Культуры</NavLink>
                </li>
            </ul>
            <ul className="list-reset aside-list">
                <li className="aside-list-item">
                    <NavLink to='/7' className={activeLink}>IBA</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/8' className={activeLink}>Info</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/9' className={activeLink}>Информация о релизе</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/10' className={activeLink}>Служба поддержки</NavLink>
                </li>
                <li className="aside-list-item">
                    <NavLink to='/profile' className={activeLink}>Учётная запись</NavLink>
                </li>
            </ul>
        </aside>
    );
}

export {Aside};
