import { Navigate } from 'react-router-dom';
import Cookies from "universal-cookie";

const RequireAuth = ({children}) => {

    const cookie = new Cookies();

    if (cookie.get('token') === undefined) {
        return <Navigate to='/login'/>
    }

    return children;

}

export {RequireAuth};
