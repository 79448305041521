import React from "react";
import AuthMenu from "../component/auth/AuthMenu";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const loginProcessing = (e) => {
        e.preventDefault();
        if (e.target.username.value === 'admin' && e.target.password.value === 'intelmap') {
            const cookies = new Cookies();
            cookies.set('token', 'user-token');
            const fromPage = location.state?.from?.pathname || '/';

            navigate(fromPage);
        } else {
            e.target.username.classList.add('invalid-input');
            e.target.password.classList.add('invalid-input');
        }
    }

    return <div className="container auth-container">
        <div className="auth">
            <AuthMenu />
            <form className="login" onSubmit={loginProcessing}>
                <span className="login-text">Электронная почта</span>
                <input type="text" className="login-input" placeholder="E-Mail" id='username'/>
                <span className="login-text">Пароль</span>
                <input type="password" className="login-input" placeholder="*************" id='password'/>
                <div className="login-bottom">
                    <label htmlFor="pass-remember">
                        <input type="checkbox" className="login-checkbox" id="pass-remember"/>
                        <span className="login-checkbox-text">Запомнить</span>
                    </label>
                    <a href="#" className="login-link">Восстановление пароля</a>
                </div>
                <button className="login-button">Войти</button>
            </form>
        </div>
    </div>;

}

export default Login;
