import Login from "./pages/Login";
import {Routes, Route} from 'react-router-dom';
import React from "react";
import {RequireAuth} from "./hoc/RequireAuth";
import {Probe} from "./pages/probe/Probe";
import {Profile} from "./pages/Profile";

function App() {

    return (
        <>
            <Routes>
                <Route path='/' element={
                    <RequireAuth>
                        <Probe />
                    </RequireAuth>
                } />
                <Route path='/profile' element={
                    <RequireAuth>
                        <Profile />
                    </RequireAuth>
                } />
                <Route path='/login' element={<Login />} />
            </Routes>
        </>
    )
}

export default App;
