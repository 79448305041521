const ProbeItem = (srcProbe) => {
    const probe = srcProbe.probe.probe;
    return (
        <li className='probe-list-item'>
            <input type="checkbox" className="probe-list-item-1"/>
            <span className="probe-list-item-2">{probe.name}</span>
            <span className="probe-list-item-3">{probe.s}</span>
            <span className="probe-list-item-4">{probe.culture}</span>
            <span className="probe-list-item-5">{probe.date}</span>
            <span className="probe-list-item-6">{probe.protein}</span>
            <span className="probe-list-item-7">{probe.humidity}</span>
            <span className="probe-list-item-8">{probe.carbohydrates}</span>
            <span className="probe-list-item-9">{probe.oil_content}</span>
            <span className="probe-list-item-10"></span>
        </li>
    );
}

export {ProbeItem};
