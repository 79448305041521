import {Aside} from "../../component/Aside";
import {ProbeCenter} from "./Probe-Center";
import {ProbeRight} from "./Probe-Right";

const Probe = () => {

  return (
      <div className="main-container">
        <Aside />
        <ProbeCenter />
        <ProbeRight />
      </div>
  );

}

export {Probe};
