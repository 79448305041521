import {ProfileHeader} from "../../component/headers/ProfileHeader";

const ProfileCenter = () => {
  return (
      <div>
        <ProfileHeader />
        <div className="profile-center-wrapper">
            <div className="profile-center-buttons">
                <button className="profile-center-button profile-center-button-active">Мои данные</button>
                <button className="profile-center-button">Пользователи</button>
                <button className="profile-center-button">Приборы</button>
                <button className="profile-center-button">Логи</button>
            </div>
            <div className="profile-center-view">
                <div className="profile-center-view-top">
                    <span>Язык</span>
                    <span>Русский</span>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Почтовый адрес</span>
                    <input type="text" className="profile-center-view-input" value="admin@admin.com"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Имя</span>
                    <input type="text" className="profile-center-view-input" value="Иван"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Фамилия</span>
                    <input type="text" className="profile-center-view-input" value="Иванов"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Номер телефона</span>
                    <input type="text" className="profile-center-view-input" value="+7 (925) 123-45-67"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Название компании</span>
                    <input type="text" className="profile-center-view-input" value="ООО Ромашка"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Индекс</span>
                    <input type="text" className="profile-center-view-input" value="123456"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Юр адрес</span>
                    <input type="text" className="profile-center-view-input" value="Москва, ул. Новая, д.1"/>
                </div>
                <div className="profile-center-view-bottom">
                    <span className="profile-center-view-text">Пароль</span>
                    <input type="password" className="profile-center-view-input" value="qwerty123"/>
                </div>
                <button className="profile-center-view-button">Сохранить</button>
            </div>
        </div>
      </div>
  );
}

export {ProfileCenter};
